import React from 'react';
import { render } from 'react-dom';
import { register as registerServiceWorker } from './registerAppServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { client } from '@xbcb/apollo-client'; // not using redux currently
import { getEnv } from '@xbcb/ui-utils';
import { uiStageToBackendStage } from '@xbcb/ui-types';

import { Provider } from 'react-redux';
import { create } from './store';
import App from 'components/App';
import { polyfillLoader } from 'polyfill-io-feature-detection';
import allSettled from 'promise.allsettled';
import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { MbmProvider } from '@amzn/react-arb-tools';
import arbManifest from 'translations/arbManifest.js';
import { translationCdnUrls } from '../src/constants';
import {
  UI_LOCALIZATION_FEATURE,
  uiLocalizationFeatureName,
} from '@xbcb/feature-flags';

const VALID_US_LOCALE = 'en-US';
export const localizationContextBuilder =
  new LocalizationContextBuilder().withDefaultLocale('en-US');

const { stage } = getEnv();

function main() {
  const isUiLocalizationEnabled = UI_LOCALIZATION_FEATURE.isEnabled(
    uiLocalizationFeatureName,
    {
      stage: uiStageToBackendStage[stage],
    },
  );
  const store = create();

  const locale = new Intl.Locale(navigator.languages?.[0]);
  const translationCdnUrl = translationCdnUrls[uiStageToBackendStage[stage]];

  const mbmOption = {
    arbManifest,
    defaultLocalizationContext: localizationContextBuilder
      .withLocale(VALID_US_LOCALE)
      .withDefaultLocale(VALID_US_LOCALE)
      .build(),
    resolveBundleUrl: (url?: string | undefined) =>
      url ? `${translationCdnUrl}/translations/${url}` : undefined,
  };
  render(
    <Provider store={store}>
      <MbmProvider {...mbmOption}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ApolloProvider>
      </MbmProvider>
    </Provider>,
    document.getElementById('root'),
  );

  registerServiceWorker({
    onUpdate: async (registration) => {
      // We want to run this code only if we detect a new service worker is
      // waiting to be activated.
      // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
      if (registration && registration.waiting) {
        // Makes Workbox call skipWaiting()
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    },
  });
}

allSettled.shim(); // will be a no-op if not needed

if (!Object.values || !Element.prototype.prepend) {
  polyfillLoader({
    features:
      'fetch,Set,Object.assign,Element.prototype.prepend,String.prototype.includes,Array.prototype.includes,Object.values,String.prototype.startsWith,String.prototype.endsWith,Array.prototype.find,String.fromCodePoint,Object.entries',
    onCompleted: main,
  });
} else {
  main();
}
